
import { Vue, Component, Ref } from 'vue-property-decorator'
import { parse } from 'papaparse'
import moment from 'moment'
import ListView from '@/components/ListView.vue'
import DateSelect from '@/components/DateSelect.vue'
import ShopAutocomplete from '@/components/ShopAutocomplete.vue'
import CSVExportBtn from '@/components/CSVExport.vue'
import { Shop } from '@/types/shop'

const parseCSV = (file: File) => {
  return new Promise((resolve, reject) => {
    parse(file, {
      error: err => {
        reject(err)
      },
      complete: results => {
        return resolve(results.data)
      },
    })
  })
}

const transformCSV = (data: string[][], shops: Shop[]) => {
  const header = data[0]
  if (!header) return []
  const shopCodeIdx = header.findIndex(x => String(x).match('コード'))
  const dateIdx = header.findIndex(x => String(x).match('日'))
  const memoIdx = header.findIndex(x => String(x).match('メモ'))
  const code2id: Map<string, Shop> = new Map()
  shops.forEach(shop => {
    code2id.set(shop.code, shop)
  })
  data = data.slice(1)
  return data.map(row => {
    const shop = code2id.get(row[shopCodeIdx])
    const date = moment(row[dateIdx])
    return {
      isValid: Boolean(shop) && date.isValid(),
      shop: shop?.id,
      shopCode: row[shopCodeIdx],
      shopName: shop?.name,
      date: date.isValid() ? date.format('YYYY-MM-DD') : '',
      memo: row[memoIdx] || '',
    }
  })
}

@Component({
  components: { ListView, DateSelect, ShopAutocomplete, CSVExportBtn },
})
export default class HolidayView extends Vue {
  @Ref() readonly listView!: ListView

  filterDefinition = {
    ordering: { type: String, default: '-created' },
    shop: { type: String, default: null },
    dateFrom: { type: String, default: null },
    dateTo: { type: String, default: null },
  }
  filter: null | Record<string, any> = null
  selected: number[] = []

  headers = [
    { text: 'チケット名称', value: 'name' },
    { text: 'グループ', value: 'groupName' },
    { text: '有効', value: 'isActive' },

    { text: 'お客様番号', value: 'customerCode', width: 100 },
    { text: 'お客様氏名', value: 'customerName', width: 100 },

    { text: '作成者', value: 'createdUsername' },
    { text: '枚数', value: 'quantity' },
    { text: '使用枚数', value: 'usedQuantity' },
    { text: '有効期限', value: 'expiryAt' },
    { text: '更新日', value: 'updated' },
    { text: '作成日', value: 'created' },
    { text: 'PubSub ID', value: 'pubsubMessageid' },
    { text: '購入金額', value: 'price' },
  ]
  shops: Shop[] = []
  bulkData: {
    isValid: boolean
    shopCode: any
    shopName: any
    shop: any
    date: string
    memo: string
  }[] = []
  dialog = false
  get csvHeaders() {
    return CSVHeaders
  }
  created() {
    this.fetchShop()
  }
  updateQuery() {
    if (this.filter !== null) this.listView.updateQuery(this.filter)
    this.selected = []
  }
  async fetchShop(nextUrl?: string) {
    const { results, next } = await (nextUrl
      ? this.$api.http.get(nextUrl)
      : this.$api.shops().list())
    this.shops = this.shops.concat(results)
    if (next) this.fetchShop(next)
  }

  async onFileChanged(file: null | File) {
    console.log('onFileChanged', file)
    if (file) {
      const csvData = (await parseCSV(file)) as any[][]
      this.bulkData = transformCSV(csvData, this.shops)
    }
  }
}
const extractTime = (value: any) => (value ? moment(value).format('HH:mm') : '')
const extractDate = (value: any) =>
  value ? moment(value).format('YYYY-MM-DD') : ''
const CSVHeaders = [
  { key: 'name', label: 'チケット名称' },
  { key: 'groupName', label: 'グループ' },
  { key: 'group', label: 'チケットID' },
  { key: 'isActive', label: '有効' },
  { key: 'customerCode', label: 'お客様番号' },
  { key: 'customerName', label: 'お客様氏名' },

  { key: 'ecProductId', label: 'EC商品ID' },

  { key: 'createdUsername', label: '作成者' },
  { key: 'quantity', label: '枚数' },
  { key: 'usedQuantity', label: '使用枚数' },
  { key: (item: any) => extractDate(item.expiryAt), label: '有効期限日' },
  { key: (item: any) => extractTime(item.expiryAt), label: '有効期限時間' },
  { key: (item: any) => extractDate(item.created), label: '作成日' },
  { key: (item: any) => extractTime(item.created), label: '作成時間' },
  { key: 'pubsubMessageid', label: 'PubSub ID' },
  { key: 'price', label: '購入金額' },
  { key: 'id', label: '購入ID' },
]
